.layout {
  background: radial-gradient(50% 50% at 50% 50%, #002140 0%, #001529 100%);
  background-size: "cover";
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
