@font-face {
  font-family: "Nunito";
  src: url("assets/fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

.ant-table table {
  font-size: 16px;
  background-color: #dfebe9;
  border-spacing: 0 12px;
}

#whiteTable table {
  background-color: #ffffff;
  border-collapse: collapse;
}

#mintTable table {
  background-color: #ffffff;
}

.ant-table-thead > tr > th {
  min-height: 62px;
  height: 62px;
}

#whiteTable .ant-table-thead > tr > th {
  background-color: #edf1ef;
}

#mintTable .ant-table-thead > tr > th {
  background-color: #edf1ef;
}

.ant-table-thead > tr > th:first-child {
  border-radius: 10px 0 0 10px;
}

.ant-table-thead > tr > th:last-child {
  border-radius: 0 10px 10px 0;
}

.app-table-row {
  background-color: #ffffff;
}

#mintTable .app-table-row {
  background-color: #edf1ef;
}

.app-table-row:hover td {
  background-color: #c1cccb !important;
}

.app-table-row > td:first-child {
  border-radius: 10px 0 0 10px;
}

.app-table-row > td:last-child {
  border-radius: 0 10px 10px 0;
}

.ant-table-cell::before {
  display: none;
}

.ant-table-cell.ant-table-selection-column {
  border-spacing: initial;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-list-footer {
  padding-block: 0 !important;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.phone-input input:focus {
  border-color: #287866;
  box-shadow: 0 0 0 2px rgba(1, 28, 17, 0.37);
  border-inline-end-width: 1px;
  outline: 0;
}

.csvButton {
  display: flex;
  align-items: center;
}

.csvButton > a {
  color: black;
}

.csvButton:hover > a {
  color: #287866;
}
